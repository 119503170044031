<template>
  <div class="operation" v-loading="loading">
    <el-select
      v-model="pageInfo.profileId"
      style="margin-bottom: 15px; width: 150px; margin-right: 10px"
      placeholder="请选择店铺"
    >
      <el-option-group
        v-for="group in shopList"
        :key="group.id"
        :label="group.name"
      >
        <el-option
          v-for="item in group.marketPlaceTree"
          :key="item.profileId"
          :label="group.name + '-' + item.name"
          :value="item.profileId"
          @click.native="getID(item.profileId, item.currency)"
          >{{ item.name }}
        </el-option>
      </el-option-group>
    </el-select>
    <TimeQuantum
      style="margin-right: 10px"
      @selectFinish="getTimeSection"
      section="近7天"
    ></TimeQuantum>
    <el-select
      clearable
      v-model="pageInfo.campaignId"
      style="width: 150px; margin-right: 10px"
      filterable
      placeholder="选择广告活动"
      @change="find"
    >
      <el-option
        v-for="item in campaignsList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="getscreenGroup(item)"
      >
        <p>
          <span>({{ item.type }}) </span>
          <span
            v-if="item.state == 'enabled'"
            style="color: #1afa29"
            class="iconfont icon-duihao"
          ></span>
          <span
            v-if="item.state == 'paused'"
            style="font-size: 14px; color: red"
            class="iconfont icon-zantingtingzhi"
          ></span>
          <span
            v-if="item.state == 'archived'"
            style="font-size: 14px; color: #dcdfe6"
            class="iconfont icon-guidang"
          ></span>
          <span> {{ item.value }}</span>
        </p>
      </el-option>
    </el-select>
    <el-select
      clearable
      v-model="pageInfo.groupId"
      style="width: 150px; margin-right: 10px"
      filterable
      placeholder="广告组"
      @change="find"
    >
      <el-option
        v-for="item in screenGroupList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="getCampaignId(item)"
      >
      </el-option>
    </el-select>
    <el-select
      style="width: 150px; margin-right: 10px"
      v-model="pageInfo.executeType"
      placeholder="执行类型"
    >
      <el-option
        v-for="item in executeTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      style="width: 150px; margin-right: 10px"
      v-model="pageInfo.result"
      placeholder="执行结果"
    >
      <el-option
        v-for="item in executeResultList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      style="width: 150px; margin-right: 10px"
      v-model="pageInfo.operateObject"
      placeholder="请选择操作对象"
    >
      <el-option
        v-for="item in operateObject"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      style="width: 150px; margin-right: 10px"
      v-model="pageInfo.operateType"
      placeholder="请选择操作类型"
    >
      <el-option
        v-for="item in operateType"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-input
      v-model="pageInfo.detail"
      style="width: 270px; margin-right: 10px"
      placeholder="ASIN/SKU/category/关键词/品牌/策略"
    ></el-input>
    <el-input
      v-model="pageInfo.createName"
      style="width: 170px; margin-right: 10px"
      placeholder="请输入操作人"
    ></el-input>
    <el-button @click="find" type="primary">立即查询</el-button>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 10px"
      :cell-style="{ fontSize: '13px', padding: '7px 0' }"
      :header-cell-style="{
        fontSize: '13px',
        padding:'5px',
        marginTop: '10px',
        background: '#EBEEF5',
      }"
    >
      <el-table-column prop="itemType" label="操作对象" width="width">
      </el-table-column>
      <el-table-column prop="operateType" label="操作类型" width="width">
      </el-table-column>
      <el-table-column prop="campaignName" label="广告活动/组合" width="width">
      </el-table-column>
      <el-table-column prop="groupName" label="广告组" width="width">
      </el-table-column>
      <el-table-column
        prop="detail"
        label="ASIN/SKU/品牌/category/关键词/策略"
        width="width"
      >
      </el-table-column>
      <el-table-column prop="beforeChange" label="操作前" width="width">
          <template slot-scope="scope">
              <pre style="font-family:normal">{{scope.row.beforeChange}}</pre>
          </template>
      </el-table-column>
      <el-table-column prop="afterChange" label="操作后" width="width">
          <template slot-scope="scope">
              <pre style="font-family:normal">{{scope.row.afterChange}}</pre>
          </template>
      </el-table-column>
      <el-table-column prop="createName" label="操作人" width="width">
      </el-table-column>
      <el-table-column prop="createTime" label="操作时间" width="150">
      </el-table-column>
      <el-table-column
        align="center"
        prop="success"
        label="操作结果"
        width="width"
      >
        <template slot-scope="scope">
          <span
            :style="{ color: scope.row.success == '成功' ? '#13CE66' : 'red' }"
            >{{ scope.row.success }}</span
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TimeQuantum from "@/components/TimeQuantum";
import request from "@/utils/request";
export default {
  components: {
    TimeQuantum
  },
  data() {
    return {
      pageInfo: {
        profileId: "",
        current: 1,
        pageSize: 10,
        campaignId: "",
        groupId: "",
        executeType: "",
        result: "",
        operateObject: "",
        operateType: "",
        detail: "",
        createName: "",
        recordId: "",
        beginTime: "",
        endTime: ""
      },
      executeResultList: [],
      executeTypeList: [],
      groupList: [],
      screenGroupList: [],
      operateObject: [],
      operateType: [],
      shopList: [],
      campaignsList: [],
      loading: false,
      tableData: []
    };
  },
  created() {
    this.getPageInit();
  },
  watch: {
    $route(to) {
      const info = this.$route.query;
      this.pageInfo.profileId = Number(info.profileId);
      this.pageInfo.campaignId = Number(info.campaignId);
      this.getListPage();
    }
  },
  methods: {
    getscreenGroup(info) {
      console.log(info);
      this.screenGroupList = this.groupList.filter(item => {
        return item.campaignId == info.id;
      });
      this.pageInfo.groupId = "";
    },
    getCampaignId(info) {
      this.pageInfo.campaignId = info.campaignId;
    },
    getID(id) {
      console.log(id);
      this.pageInfo = {
        profileId: this.pageInfo.profileId,
        current: 1,
        pageSize: 10,
        campaignId: "",
        groupId: "",
        executeType: "",
        result: "",
        operateObject: "",
        operateType: "",
        detail: "",
        createName: "",
        recordId: "",
        beginTime: this.pageInfo.beginTime,
        endTime: this.pageInfo.endTime
      };
      this.pageInfo.profileId = id;
      this.getPageInit("currentPage");
    },
    getTimeSection(val) {
      console.log(val);
      this.pageInfo.beginTime = val.value[0];
      this.pageInfo.endTime = val.value[1];
      this.current = 1;
      if (this.pageInfo.profileId == "") return;
      this.getListPage();
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getListPage();
    },
    handleCurrentChange(val) {
      this.pageInfo.current = val;
      this.getListPage();
    },
    getPageInit(val) {
      this.loading = true;
      request({
        url: "/api/amzad/record/item/pageInit",
        method: "get",
        headers: {
          profileId:
            this.pageInfo.profileId || this.$store.state.profileId
              ? this.pageInfo.profileId || this.$store.state.profileId
              : 0
        }
      }).then(res => {
        console.log(res);
        this.executeResultList = res.data.data.executeResultList;
        this.executeTypeList = res.data.data.executeTypeList;
        this.groupList = res.data.data.groupList;
        this.operateObject = res.data.data.operateObject;
        this.operateType = res.data.data.operateType;
        this.campaignsList = res.data.data.campaignsList;
        this.shopList = res.data.data.shopList;
        this.screenGroupList = res.data.data.groupList;
        // if (this.pageInfo.campaignId) {
        //   console.log(1);
        //   this.screenGroupList = this.groupList.filter(item => {
        //     return item.campaignId == this.campaignId;
        //   });
        // } else {
        //   console.log(2);
          // this.screenGroupList = this.groupList;
        // }
        if (this.pageInfo.profileId == "") {
          this.shopList.forEach(item => {
            item.marketPlaceTree.forEach(i => {
              if (i.isSelected == 1) {
                this.pageInfo.profileId = i.profileId;
                this.pageInfo.current = 1;
                this.currency = i.currency;
              }
            });
          });
        }
        if (val == "currentPage") {
          this.getListPage();
          return;
        }
        let info = this.$route.query;
        if (JSON.stringify(info) != "{}") {
          const info = this.$route.query;
          this.pageInfo.profileId = Number(info.profileId);
          this.pageInfo.campaignId = Number(info.campaignId);
          this.screenGroupList = this.groupList.filter(item => {
            return item.campaignId == this.pageInfo.campaignId;
          });
          this.getListPage();
        } else {
          this.getListPage();
        }
      });
    },
    getListPage() {
      this.loading = true;
      this.$store.commit("setProfileId", this.pageInfo.profileId);
      request({
        url: "/api/amzad/record/item/operateListPage",
        method: "get",
        params: this.pageInfo,
        headers: {
          profileId: this.pageInfo.profileId
        }
      }).then(res => {
        console.log(res);
        this.tableData = res.data.values;
        this.total = res.data.pageInfo.total;
        this.$nextTick(() => {
          this.loading = false;
        });
      });
    },
    find() {
      this.pageInfo.current = 1;
      this.getListPage();
    }
  }
};
</script>

<style>
</style>